//
// Navs
// --------------------------------------------------


// Base class
// --------------------------------------------------


// Tabs
// -------------------------

// Give the tabs something to sit on
.nav-tabs {
  border-bottom: 2px solid $nav-tabs-border-color;
  > li {
    float: left;
    // Make the list-items overlay the bottom border
    margin-bottom: -2px;

    // Actual tabs (as links)
    > a {
      margin-right: 2px;
      line-height: $line-height-base;
      border: 2px solid transparent;
      border-right: none;
      border-left: none;
      border-top: none;
      
      border-radius: $border-radius-base $border-radius-base 0 0;
      transition: color 0.2s ease-in-out;
      &:hover {
        border-color: $nav-tabs-link-hover-border-color $nav-tabs-link-hover-border-color $nav-tabs-border-color;
        //border-bottom: 2px solid $nav-tabs-border-color;
      }
    }

    // Active state, and its :hover to override normal :hover
    &.active > a {
      padding: 8px 13px 12px 13px;
      border-bottom: none;
      &,
      &:hover,
      &:focus {
        border: 2px solid $nav-tabs-justified-link-border-color;
        border-bottom: none;
      }
    }
  }
}


// Nav variations
// --------------------------------------------------

// Move borders to anchors instead of bottom of list
//
// Mixin for adding on top the shared `.nav-justified` styles for our tabs
.nav-tabs-justified {
  border-bottom: 0;

  > li > a {
    // Override margin from .nav-tabs
    margin-right: 0;
    border-right: none;
    border-left: none;
    padding: 12px 17px;
    border-radius: $border-radius-base;
    transition: color 0.2s ease-in-out;
  }

  > .active > a,
  > .active > a:hover,
  > .active > a:focus {
    border-bottom: none;
    border: 2px solid $nav-tabs-justified-link-border-color;
  }

  @media (min-width: $screen-sm-min) {
    > li > a {
      border-bottom: 2px solid $nav-tabs-justified-link-border-color;
      border-radius: $border-radius-base $border-radius-base 0 0;
    }
    > .active > a,
    > .active > a:hover,
    > .active > a:focus {
      padding: 10px 15px 14px 15px;
      border-bottom: none;
      border-bottom-color: $nav-tabs-justified-active-link-border-color;
    }
  }
}
