.nav-padding {
  
}

.sticky-nav {
  z-index: 1000;
  width: 100%;
  background-color: $white;
  @extend .clearfix;
  .hours {
    transition: opacity 0.6s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    opacity: 0;
  }
  @media (min-width: $screen-sm-min) {
    .brand {
      transition: opacity 0.6s;
      transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
      opacity: 0;
    }
  }
  &.in-view {
    position: fixed;
    top: 0;
    //left: 0;
    transform: translate3d(0,0,0);
    .hours {
      opacity: 1;
    }
    @media (min-width: $screen-sm-min) {
      .brand {
        opacity: 1;
      }
    }
  }
}

.banner {
  position: relative;
  height: 70px;
  width: 100%;
  background-color: $white;
  color: $black;
  text-align: center;
  font-size: 20px;
  @include clearfix();
  @media (min-width: $screen-lg-min) {
    font-size: 24px;
  }
  .brand {
    @include make-sm-column(3);
    @media (min-width: $screen-sm-min) {
      text-align: left;
    }
    .logo {
      display: block;
      margin-top: 10px;
      margin-bottom: 10px;
      width: 50px;
      height: 50px;
      background-repeat: no-repeat;
      @include img-retina("../images/churchill-dot.png","../images/churchill-dot@2x.png",50px,50px);
      @extend .text-hide;
    }
  }
  nav {
    @include make-sm-column(6);
    background-color: $white;
    ul.nav {
      li {
        @extend .font-headline;
        //text-align: left;
        //text-align: center;
        display: inline-block;
        a {
          padding: 15px 10px;
          transition: color 0.2s ease-in, transform 0.2s ease-in, opacity 0.2s ease-in;
          @media (min-width: $screen-sm-min) {
            padding: 25px 15px;
            font-size: 23px;
          }
          @media (min-width: $screen-lg-min) {
            padding: 23px 15px;
            font-size: 24px;
          }
          &:hover,
          &:active,
          &:focus {
            color: $black;
            background-color: transparent;
          }
        }
        &.active > a {
          color: $black;
        }
      }
    }
  }
  .hours {
    @include make-sm-column(3);
    @media (min-width: $screen-sm-min) {
      text-align: right;
    }
    @media (min-width: $screen-lg-min) {
      padding: 23px 15px;
    }
    span {
      display: block;
      @media (min-width: $screen-lg-min) {
        display: inline-block;
      }
    }
    .hours-time {
      color: darken($text-alt, 20%);
      @media (min-width: $screen-lg-min) {
        margin-left: 8px;
      }
    }
  }
  /* Old Hour Styling
  .hours {
    @include make-sm-column(3);
    @media (min-width: $screen-sm-min) {
      text-align: right;
    }
    @media (min-width: $screen-lg-min) {
      padding: 23px 15px;
    }
    span {
      @media (min-width: $screen-sm-min) {
        display: block;
      }
      @media (min-width: $screen-lg-min) {
        display: inline-block;
      }
    }
    .hours-time {
      margin-left: 8px;
      color: darken($text-alt, 20%);
    }
  }
  */
}

//mobile menu
header.banner {
  @media (max-width: $screen-xs-max) {
    background-color: transparent;
    .brand {
      background-color: white;
      padding: 5px 15px;
      .logo {
        margin: 0;
      }
    }
    nav {
      background-color: lighten($silver, 16%);
      padding: 0;
      min-height: 0;
      max-height: 0;
      overflow: hidden;
      //transform: translate3d(0,-100%,0);
      transition: all 0.5s ease-out;
      ul > li > a {
        opacity: 0;
        transform: translate3d(0,15px,0);
      }
      &.menu-open {
        max-height: 200px;
        //transform: translate3d(0,0,0);
        ul > li > a {
          opacity: 1;
          transform: translate3d(0,0,0);
        }
      }
    }
    .hours {
      text-align: left;
      position: absolute;
      top: 0;
      left: 65px;
      padding: 10px;
      .hours-status {
        color: $black;
      }
      .hours-time {
        color: $silver;
      }
    }
  }
}

.toggle-menu {
  z-index: 15;
  position: absolute;
  top: 15px;
  right: 5px;
  padding: 14px 15px 13px 15px;
  overflow: hidden;
  text-indent: 10em;
  outline: 0 solid transparent;
  background-color: transparent;
  border: 0px solid transparent;
  color: $black;
  @media (min-width: $screen-sm-min) {
    display: none;
    visibility: hidden;
  }
  span,
  span:before,
  span:after {
    width: 20px;
    height: 3px;
    background: $black;
    @include transition(.2s);  
  }
  span {
    display: block;
    position:relative;
    &:before {
      content:'';
      position:absolute;
      top: -6px;
      left: 0;
    }
    &:after {
      content:'';
      position:absolute;
      top: 6px;
      left: 0;
    }
  }
  &.menu-active span {
    background: transparent;
    &:before {
      transform: rotate(45deg);
      top:0;
    }
    &:after {
      transform: rotate(-45deg);
      top:0;
    }
  }
}

