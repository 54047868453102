body {
  font-size: 16px;
  //font-size: 14px;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, .font-headline {
  font-family: $font-family-sans-serif;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 0.025em;
  line-height: 1;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lead {
  margin-bottom: $line-height-computed;
  font-size: floor(($font-size-base * 1.15));
  font-weight: 300;
  line-height: 1.4;

  @media (min-width: $screen-sm-min) {
    font-size: 24px;
    font-family: $font-family-sans-serif;
    letter-spacing: 0.025em;
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

blockquote {
  @extend .font-headline;
  font-size: 36px;
  border: 0;
  padding: 0;
}

.nav {
  @extend .font-headline;
  font-size: 22px;
}

.nav-fixed {
  margin-top: 60px;
  @media (min-width: $screen-sm-min) {
    margin-top: 70px;
  }
}

.links-silver {
  a {
    color: $silver;
    &:hover,
    &:active,
    &:focus {
      color: lighten($silver, 15%);
    }
  }
}

.section-info,
.twitter-feed {
  @extend .links-silver;
}

/*======= HOURS WIDGET =======*/

.hours {
  @extend .font-headline;
  padding: 15px 10px;
  font-size: 20px;
  @media (min-width: $screen-lg-min) {
    font-size: 24px;
  }
}

/*======= BORDERED CONTAINERS =======*/

.home .bordered {
  min-height: 330px;
}

.bordered {
  position: relative;
  padding: 20px;
  border-width: 2px;
  border-color: $brdr-color;
  border-style: solid;
  background-color: $bg-color;
  margin-top: 18px;
  @media (min-width: $screen-sm-min) {
    margin-top: 0;
  }
  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 1px;
    left: 1px;
    bottom: -5px;
    right: -5px;
    background-image: linear-gradient(to bottom, rgba(0,0,0,0) 75%, $brdr-color 50%);
    background-repeat: repeat;
    background-size: 2px 3px;
    //background-size: 0.21em 0.21em;
    background-position: 0 bottom;
  }
}

.bordered-content {
  margin-bottom: 57px;
}

.bordered-link {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px 20px;
  width: 100%;
  border-top: 2px solid $brdr-color;
  @extend .font-headline;
  font-size: 18px;
  transition: color 0.2s ease-in-out;
  span {
    display: inline-block;
    color: $white;
    text-decoration: none;
    transform: translate3d(0,0,0);
    transition: transform 0.4s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  }
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    span {
      transform: translate3d(4px,0,0);
    }
  }
}

.twitter-feed,
.twitter-feed .tweet {
  @extend .font-headline;
  font-size: $font-size-h3;
  font-weight: 100;
  text-align: center;
  margin-top: 0;
}

.twitter-follow {
  margin-top: 15px;
  font-size: $font-size-h4;
  font-weight: 700;
  a {
    color: $link-color;
    &:hover,
    &:active,
    &:focus {
      color: lighten($link-color, 25%);
    }
  }
}

/*======== VIDEO LANDING ========*/

.logo-stacked-container {
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.logo-stacked {
  width: calc(514px / 2);
  height: calc(151px / 2);
  background-size: cover;
  background-image: url("../images/churchill-stacked.png");
  background-repeat: no-repeat;
  @media (min-width: $screen-sm-min) {
    width: 514px;
    height: 151px;
  }
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
  not all, not all, not all,
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  background-image: url("../images/churchill-stacked@2x.png");
  }
}

#section-video {
  position: relative;
  min-height: calc(100vh - 70px);
  background: #000;
  overflow: hidden;
  .video {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    //width: 100%;
    //height: 0;
    //padding-bottom: 56.25%;
    width: 100%;
    height: 100%;
  }
  video {
    position: absolute;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    //z-index: -1000;
    overflow: hidden;
    @supports(object-fit: cover) {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .video-element {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .hours {
    z-index: 10;
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
    .hours-time {
      margin-left: 8px;
      color: $brdr-color;
    }
    span {
      display: inline-block;
    }
  }
}

/*======== SECTIONS ========*/

main > section {
  @include make-row();
  margin-top: 55px;
  @media (min-width: $screen-sm-min) {
    margin-top: 110px;
  }
  h1, h2, h3 {
    &:first-child {
      margin-top: 0;
    }
  }
}

.section-header {
  text-align: center;
  h1, h2, h3,
  .h1, .h2, .h3 {
    margin-bottom: 30px;
  }
}

.bg-distress {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    pointer-events: none;
    z-index: -5;
    top: 50%;
    left: 50%;
    transform: translate3d(-49.9%,-50%,0);
    width: 445px;
    height: 458px;
    max-width: 100%;
    @include img-retina("../images/distress.png","../images/distress@2x.png",445px,458px);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }
}


#section-about {
  h1, blockquote {
    margin-bottom: 35px;
  }
}

#food-menu {
  .tab-pane {
    padding-top: 20px;
  }
}

#section-pub,
#section-history {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
  padding-top: 55px;
  padding-bottom: 45px;
  @media (min-width: $screen-sm-min) {
    padding-top: 85px;
    padding-bottom: 75px;
  }
  @media (min-width: $screen-md-min) {
    padding-top: 160px;
    padding-bottom: 150px;
  }
}

#section-pub {
  background-image: url("../images/churchill-pub-colour-correct.jpg");
  background-position: center center;
}


#section-history {
  color: $black;
  background-color: $white;
  background-image: url("../images/churchill-old-mobile.jpg");
  @media (max-width: $screen-sm-max) {
    background-size: contain;
    background-position: center bottom;
    padding-bottom: 120%;
  }
  @media (min-width: $screen-md-min) {
    background-image: url("../images/churchill-old2.jpg");
    background-size: 100%;
    background-position: right bottom;
  }
  @media (min-width: $screen-lg-min) {
    background-size: cover;
    background-position: right center;
  }
}

.section-info {
  background-color: lighten($bg-alt-color, 2%);
}

/*======== MENU PAGES ========*/

.section-menu {
  padding-bottom: 80px;
}

.tab-pane {
  .row {
    display: flex;
    flex-wrap: wrap;
  }
}

.jsrm-menu {
  //border-top: 2px solid $brdr-color;
  margin: 0;
  padding-top: 10px;
  //@media (min-width: $screen-sm-min) {
  //  border: none;
  //}
  ul {
    border-top: none;
    li {
      border-bottom: none;
      div {
        padding: 0;
        width: 100%;
      }
    }
  }
  .jsrm-menu-header {
    color: $btn-color;
    //cursor: pointer;
    font-size: 22px;
  }
  .jsrm-menu-description {
    font-style: italic;
    color: $text-light;
    margin: 10px 0;
  }
  .item {
    border: 0;
  }
 th {
   border: 0;

   @extend h2;
   @extend .jsrm-menu-header;
 }

  .item-text {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 2px;
  }
  .desc {
    font-size: 16px;
    color: $text-light;
    margin-bottom: 15px;
  }
  .value {
    display: none;
    visibility: hidden;
  }
}

.tab-pane {
  padding-top: 20px;
}

.cocktail-price {
  padding-top: 10px;
  //i { background-color: $brdr-color; }
  //color: $black;
  //padding: 20px;
}


//map

#google-map {
  width: 100%;
  height: 100%;
}

.map-container {
  position: relative;
  height: 260px;
}

.map-col {
  padding-left: 0;
  padding-right: 0;
}
