.footer-col {
  @media (max-width: $screen-xs-max) {
    &-nav {
      margin-bottom: 15px;
    }
    &-contact {
      margin-bottom: 10px;
    }
    &-hours {
      margin-bottom: 20px;
    }
    &-logo {}
  }
}

.logo-inline {
  height: 18px;
  width: 100%;
  margin-top: 4px;
  margin-bottom: 16px;
  @include img-retina("../images/logo-inline.png","../images/logo-inline@2x.png",250px,18px);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain !important;
  @extend .text-hide;
}

.content-info {
  position: relative;
  margin-top: 55px;
  padding-top: 35px;
  background-color: $bg-alt-color;
  font-size: 14px;
  @media (min-width: $screen-sm-min) {
    margin-top: 110px;
    padding-top: 75px;
  }
  .h1, .h2, .h3, h1, h2, h3 {
    margin-top: 0;
  }
}

.social.row {
  background-color: $bg-color;
  margin-top: 35px;
  padding-top: 35px;
  padding-bottom: 35px;
  @media (min-width: $screen-sm-min) {
    text-align: center;
    margin-top: 55px;
  }
  .social-list {
    @extend .font-headline;
    font-size: $font-size-h4;
    margin-bottom: 0;
    .social-label {
      color: $white;
      padding-left: 5px;
      padding-right: 10px;
      @media (max-width: $screen-xs-max) {
        display: none;
      }
    }
    li {
      &:last-child {
        .social-label {
          padding-right: 0;
        }
      }
    }
  }
}

body.about {
  .content-info {
    margin-top: 0;
  }
}

.footer-nav {
  @extend .list-unstyled;
  @extend .font-headline;
  font-size: 20px;
  line-height: 1.275;
  margin-bottom: 20px;
  li {
    > a {
      display: block;
    }
    @media (max-width: $screen-xs-max) {
      > a {
        border-bottom: 1px solid;
        padding: 10px 0;
        border-top: 1px solid;
      }
      &:first-child > a {
        border-top: 2px solid;
      }
      &:last-child > a {
        border-bottom: 2px solid;
      }
    }
  }
}

.content-info .circle-btn {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}