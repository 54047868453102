.page-header {
  padding-bottom: (($line-height-computed / 2) - 1);
  margin-top: 55px;
  border-bottom: 0 solid $page-header-border-color;
  text-align: center;
  @media (min-width: $screen-sm-min) {
    margin-top: 110px;
  }
  h1 {
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 72px;
    position: relative;
  }
  h2 {
    margin: 30px auto 0 auto;
    font-size: 36px;
    @media (min-width: $screen-sm-min) {
      width: 50%;
    }
  }
}

.border-square {
  margin: 0 auto;
  margin: 0 auto 3px auto;
  position: relative;
  left: -3px;
  width: 11px;
  height: 11px;
  border: 2px solid $btn-color;
  &:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    width: 11px;
    height: 11px;
    border: 2px solid $btn-color;
  }
}

.on-tap-container {
  z-index: 1;
  position: relative;
  //padding: 30px;
  //background-color: $grey;
  @extend .clearfix;
  .instagram {
    margin-bottom: 20px;
    @media (min-width: $screen-sm-min) {
      margin-bottom: 0;
    }
    .img-responsive {
      display: block;
      max-width: none;
      width: 100%;
      height: auto;
    }
  }
  .on-tap-content {
    //padding: 30px;
    //padding: 0 30px;
    //margin-bottom: 56px + 30px;
    .on-tap-link {
      margin-top: 0;
      text-align: left;
      h2 {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 2px solid $brdr-color;
      }
      a {
        margin-top: 25px;
        //display: block;
      }
    }
  }
}

.on-tap-link {
  margin-top: 60px;
  text-align: center;
  a {
    margin-top: 20px;
  }
}

#instafeed {
  .sbi_item a {
    position: relative;
    img {
      border: 2px solid $brdr-color;
    }
    .sbi-screenreader {
      z-index: 1;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 90%;
      @extend .font-headline;
      color: $white;
      font-size: $font-size-h3;
      text-align: center;
      opacity: 0;
      transform: translateX(-50%) translateY(10px);
      transition: opacity 0.6s cubic-bezier(0.2, 1, 0.3, 1), transform 0.6s cubic-bezier(0.2, 1, 0.3, 1);

      // Reset screenreader defaults
      text-indent: initial !important;
      width: initial !important;
      height: initial !important;
      line-height: initial !important;
      text-align: initial !important;
    }
    &:before {
      z-index: 0;
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      right: 2px;
      bottom: 2px;
      pointer-events: none;
      opacity: 0;
      background-color: rgba($btn-color, 0.8);
      transition: opacity 0.8s cubic-bezier(0.2, 1, 0.3, 1);
    }
    &:after {
      z-index: 0;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      margin: 3px;
      border: 2px solid $brdr-color;
    }
    &:hover {
      &:before {
        opacity: 1;
      }
      .sbi-screenreader {
        opacity: 1;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }
}

/*
.on-tap-container {
  z-index: 1;
  position: relative;
  background-color: $grey;
  @extend .clearfix;
  figure.instagram,
  .on-tap-content {
    width: 100%;
    float: left;
    @media (min-width: $screen-sm-min) {
      width: 50%;
    }
  }
  .instagram .img-responsive {
    display: block;
    max-width: auto;
    width: 100%;
    height: auto;
  }
  .on-tap-content {
    padding: 30px;
    //padding: 0 30px;
    //margin-bottom: 56px + 30px;
  }
}

.on-tap-link {
  margin-top: 20px;
  a {
    @media (max-width: $screen-xs-max) {
      display: block;
    }
  }
}
*/

.info-container {
  padding-top: 55px;
  padding-bottom: 55px;
  @media (min-width: $screen-sm-min) {
    padding-top: 85px;
    padding-bottom: 85px;
  }
  @media (min-width: $screen-md-min) {
    padding-top: 160px;
    padding-bottom: 160px;
  }
}

.food-gallery {
  opacity: 0;
  margin-bottom: 30px;
  transition: opacity 0.6s ease-in-out;
  @media (min-width: $screen-sm-min) {
    margin-bottom: 0;
  }
  &.flickity-enabled {
    opacity: 1;
  }
  .gallery-cell {
    width: 66.6666666667%;
    img {
      width: 100%;
      height: auto;
      padding: 0 15px;
    }
  }
}

.flickity-page-dots {
  bottom: -30px;
  @media (min-width: $screen-sm-min) {
    bottom: 15px;
  }
}
/* white circles */
.flickity-page-dots .dot {
  width: 12px;
  height: 12px;
  opacity: 1;
  background: transparent;
  border: 2px solid white;
  transition: border 0.2s ease-in-out;
}
/* fill-in selected dot */
.flickity-page-dots .dot.is-selected {
  border: 2px solid $brdr-color;
}

#specials .value {
    display: block;
    visibility: visible;
    & > div {
      text-transform: uppercase;
      text-decoration: underline;
    }
}
