
/*======= CIRCLE BUTTON =======*/

.arrow-up {
	width: 0; 
	height: 0; 
	border-left: 5px inset transparent;
	border-right: 5px inset transparent;
	border-bottom: 8px solid $btn-color;
}

.arrow-down {
	width: 0; 
	height: 0; 
	border-left: 6px inset transparent;
	border-right: 6px inset transparent;
	border-top: 10px solid $btn-color;
}

.circle-btn {
  padding: 12px 10px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 2px solid $btn-color;
  background-color: $black;
  outline: 0 solid transparent;
  transition: background 0.4s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  .arrow-up,
  .arrow-down {
    position: relative;
    top: -2px;
    transition: border 0.2s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  }
  &:hover,
  &:active {
    background-color: $btn-color;
    .arrow-up {
      border-bottom-color: $white;
    }
    .arrow-down {
      border-top-color: $white;
    }
  }
}

.font-buttons {
  font-family: $font-family-sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.025em;
  -webkit-font-smoothing: antialiased;
}

.btn {
  @extend .font-buttons;
  border-width: 2px;
}

.btn-default {
  position: relative;
  background-color: $bg-color;
  //background-color: transparent;
  transition: all 0.2s ease-out;
  &:after {
    content: "";
    position: absolute;
    pointer-events: none;
    z-index: -2;
    top: 1px;
    left: 1px;
    bottom: -5px;
    right: -5px;
    background-image: linear-gradient(to bottom, rgba(0,0,0,0) 75%, $btn-color 50%);
    background-repeat: repeat;
    background-size: 2px 3px;
    //background-size: 0.21em 0.21em;
    background-position: 0 bottom;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 calc(100% - 3px), calc(100% - 3px) calc(100% - 3px), calc(100% - 3px) 0);
  }
  &:before {
    content: "";
    position: absolute;
    pointer-events: none;
    z-index: -1;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: $bg-color;
    //background: transparent;
    z-index: -1;
    transition: width 0.4s, background 0.4s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  }
  &:hover {
    color: white;
    background-color: transparent;
    border-color: $btn-color;
    &:before {
      opacity: 1;
      width: 100%;
      background: $btn-color;
    }
  }
}

.btn-external {
  position: relative;
  background-color: $bg-color;
  border-color: $brdr-color;
  color: $brdr-color;
  transition: all 0.2s ease-out;
  &:after {
    content: "";
    position: absolute;
    pointer-events: none;
    z-index: -2;
    top: 1px;
    left: 1px;
    bottom: -5px;
    right: -5px;
    background-image: linear-gradient(to bottom, rgba(0,0,0,0) 75%, $brdr-color 50%);
    background-repeat: repeat;
    background-size: 2px 3px;
    //background-size: 0.21em 0.21em;
    background-position: 0 bottom;
  }
  &:before {
    content: "";
    position: absolute;
    pointer-events: none;
    z-index: -1;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: $bg-color;
    z-index: -1;
    transition: width 0.4s, background 0.4s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  }
  &:hover {
    color: white;
    background-color: transparent;
    border-color: $brdr-color;
    &:before {
      opacity: 1;
      width: 100%;
      background: $brdr-color;
    }
  }
}


.btn-xl {
  // line-height: ensure even-numbered height of button next to large input
  @include button-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $line-height-large, $border-radius-large);
  @media (min-width: $screen-sm-min) {
    @include button-size($padding-large-vertical, $padding-large-horizontal, $font-size-h3, $line-height-large, $border-radius-large);
  }
}


/*====== SCROLL DOWN BUTTON ======*/

#scroll-down-button,
#scroll-down-circle,
#scroll-down-circle .arrow-down {
  transition: all 0.4s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

#scroll-down-button {
  z-index: 10;
  position: absolute;
  height: 100px;
  width: 100px;
  bottom: 15px; 
  left: 50%;
  transform: translateX(-50%);
  color: $btn-color;
  &:hover,
  &:active {
    color: #fff;
    bottom: 18px;
    #scroll-down-circle {
      background-color: $btn-color;
    }
    .arrow-down {
      border-top-color: #fff;
    }
  }
}

#scroll-down-circle {
  position: absolute;
  top: 25px;
  left: 25px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid $btn-color;
  background-color: transparent;
  .arrow-down {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

#scroll-down-text {
  height: 100px;
  width: 100px;
  @include rotated-text(8, 90deg, -49deg);
  @media (max-width: $screen-xs-max) {
    display: none;
  }
  span {
    @extend .font-headline;
    position: absolute;
    width: 100px;
    height: 50px;
    left: 0;
    top: 0;
    font-size: 18px;
    text-align: center;
    transform-origin: bottom center;
  }
  .char1 {
    left: -1px;
  }
}
